<template>
  <module
    ref="module"
    id="revalidations"
    titleIcon="fa fa-refresh"
    :title="$t('revalidations')"
    :use-default-list="false"
  >
    <template slot="toolbar-global">
        <p-button type="danger" v-on:click="revalidateAll">
          <i class="fa fa-upload mr-1" ></i><span>{{ $t('revalidate_all') }}</span>
        </p-button>
    </template>

    <div slot="global">
      <!-- In progress -->
      <div v-if="inprogress" class="text-center">
        <h2>{{ inprogresstxt }}</h2>
        <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
      </div>

      <div v-else class="row">
        <!-- Modal for Confirm Removal -->
        <modal :show.sync="modalconfirm" headerClasses="justify-content-center">
          <h4 slot="header" class="title title-up">{{ $t('delacctitle') }}?</h4>

          <div class="row" v-if="acc.is_master">
            <div class="col-12 text-danger text-center">
              <h6>{{ $t('master') }}</h6>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              {{ $t('confirmdelacc') }}
            </div>
          </div>

          <div class="row" v-if="acc.is_master">
            <div class="col-12 text-danger text-center">
              <h6>{{ $t('strategyremovaltxt') }}</h6>
            </div>
          </div>


          <template slot="footer">
            <div class="left-side">
              <p-button @click.prevent="confirmedDel" link>{{ $t('yestxt') }}</p-button>
            </div>
            <div class="divider"></div>
            <div class="right-side">
              <p-button type="danger" v-on:click="modalconfirm = false" link>{{ $t('notxt') }}</p-button>
            </div>
          </template>
        </modal>
        <!-- End Modal -->

         <!-- Modal for force revalidation -->
        <modal :show.sync="modalrevalidation" headerClasses="justify-content-center">
          <h4 slot="header" class="title title-up">
            {{ $t('forcerevalidation') }}
          </h4>

          <div class="row">
            <div class="col-6">
              <fg-input
                :label="`${$t('accountNumber')}`"
                readonly="true"
                v-model="account.present">
              </fg-input>
            </div>
            <div class="col-6">
              <fg-input
                :label="`${$t('brokerservers')}`"
                readonly="true"
                v-model="account.server">
              </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <fg-input
                :label="`${$t('usertxt')}`"
                readonly="true"
                v-model="account.userid">
              </fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col-2">
            </div>
            <div class="col-8">
              <fg-input
              :label="`${$t('revalidationsymbol')}`"
              v-model="account.symbol">
              </fg-input>
            </div>
          </div>

          <template slot="footer">
            <div class="left-side">
              <p-button type="success" v-on:click="sendToRevalidate()" link>{{ $t('confirm') }}</p-button>
            </div>
            <div class="divider"></div>
            <div class="right-side">
              <p-button type="danger" v-on:click="modalrevalidation = false; account = {};" link>{{ $t('cancel') }}</p-button>
            </div>
          </template>
        </modal>
        <!-- End Modal -->

        <modal :show.sync="modalup" headerClasses="justify-content-center">
          <h4 slot="header" class="title title-up">{{ $t('upaccounttitle') }}</h4>

          <div class="row text-center">
            <div class="col-12">
              <fg-input v-model="acc.account" addon-left-icon="fa fa-archive" type="number"
                        :placeholder="helper_accentry"></fg-input>
            </div>
          </div>

          <div class="row text_center">
            <div class="col-12">
              <fg-input v-model="acc.broker" addon-left-icon="fa fa-building" type="text"
                        :placeholder="helper_accbroker" v-on:keyup="searchBrokers"></fg-input>
              <ul v-if="showAutoResultsBroker" class="auto-ul">
                <li v-for="r in autoResultsBroker" v-on:click="pickedBroker(`${r}`)">{{ r }}</li>
              </ul>
            </div>
          </div>

          <div class="row text-center" v-if="acc.broker">
            <div class="col-12">
              <fg-input v-model="acc.server" addon-left-icon="fa fa-server" type="text" :placeholder="helper_accserver"
                        v-on:keyup="searchServers"></fg-input>
              <ul v-if="showAutoResults" class="auto-ul">
                <li v-for="r in autoResults" v-on:click="pickedServer(`${r}`)">{{ r }}</li>
              </ul>
            </div>
          </div>

          <div class="row text-center">
            <div class="col-12">
              <fg-input v-model="acc.password" addon-left-icon="fa fa-key" type="text"
                        :placeholder="helper_accpwd"></fg-input>
            </div>
          </div>

          <template slot="footer">
            <div class="left-side">
              <p-button @click.prevent="validate" link>{{ $t('upentrybtn') }}</p-button>
            </div>
            <div class="divider"></div>
            <div class="right-side">
              <p-button type="danger" v-on:click="modalup = false" link>{{ $t('cancel') }}</p-button>
            </div>
          </template>
        </modal>
        <!-- End Modal -->

        <div class="col-12 text-center" v-if="alert_me">
          <div :class="alert_class" class="ml-2 mb-2" style="display: block;font-weight: bold;">
            {{ alert_msg }}
          </div>
        </div>

        <div class="col-md-12">
          <div class="row">

            <div class="col-sm-2">
              <label> {{ sortbyTxt }}</label>
              <el-select v-model="sortby.column" :placeholder="sortbyTxt"
                         v-on:change="sortByCol">
                <el-option class="select-default"
                           v-for="item in sortby.columns"
                           :key="item.prop" :label="item.label" :value="item.prop">
                </el-option>
              </el-select>
            </div>

            <div class="col-sm-2">
              <label> {{ perpageTxt }} </label>
              <el-select
                v-model="pagination.perPage" :placeholder="perpageTxt">
                <el-option class="select-default"
                           v-for="item in pagination.perPageOptions"
                           :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
            </div>

            <div class="col-sm-2">
              <label> {{ sortbyOrder }}</label>
              <el-select
                v-model="sortby.order" :placeholder="sortbyOrder"
                v-on:change="sortByCol">
                <el-option class="select-default"
                           v-for="item in sortby.orders"
                           :key="item.prop" :label="item.label" :value="item.prop">
                </el-option>
              </el-select>
            </div>

            <div class="col-sm-6">
              <div class="pull-right">
                <label> {{ searchTxt }}</label>
                <fg-input class="input-sm"
                          v-model="searchQuery"
                          addon-right-icon="nc-icon nc-zoom-split">
                </fg-input>
              </div>
            </div>
            <div class="col-sm-12 mt-2">
              <el-table class="table-striped"
                        :data="queriedData"
                        border
                        style="width: 100%">

                <el-table-column :label="actionlbl" min-width="250" width="250">
                  <div slot-scope="scope" class="text-center">
                    <div class="btn-group">
                      <el-tooltip :content="`${$t('copy_pass')}`" placement="top">
                        <p-button type="success" link v-on:click="showpass(`${scope.row.id}`, `${scope.row.password}`)">
                          <i class="fa fa-key" style="font-size: x-large;"></i>
                        </p-button>
                      </el-tooltip>
                      <el-tooltip :content="`${$t('edit_revalidation')}`" placement="top">
                        <p-button type="info" link v-on:click="modify(`${scope.row.id}`)">
                          <i class="fa fa-pencil" style="font-size: x-large;"></i>
                        </p-button>
                      </el-tooltip>
                      <el-tooltip :content="`${$t('delete_account')}`" placement="top">
                      <p-button type="danger" link v-on:click="delacc(`${scope.row.id}`)">
                        <i class="fa fa-ban" style="font-size: x-large;"></i>
                      </p-button>
                      </el-tooltip>

                      <el-tooltip
                        v-if="!!scope.row.in_queue_for_revalidation"
                        :content="$t('already_in_revalidation_queue')"
                        :open-delay="100"
                        placement="top">
                        <p-button  link>
                          <i class="fa fa-upload" style="font-size: x-large;"></i>
                        </p-button>
                      </el-tooltip>
                      <el-tooltip v-else :content="`${$t('force_revalidation')}`" placement="top">
                        <p-button type="warning" link :disabled="!!scope.row.in_queue_for_revalidation" v-on:click="forceRevalidation(scope.row)">
                          <i class="fa fa-upload" style="font-size: x-large;"></i>
                        </p-button>
                      </el-tooltip>
                      <manual-revalidation :account="scope.row.account"></manual-revalidation>
                    </div>
                  </div>
                </el-table-column>

                <el-table-column
                  :label="`${this.$t('account')}`">
                  <template slot-scope="scope">
                <span v-on:click="copyit(scope.row.account)" :class="`clickable ${handleLineClass(scope.row.counter)}`">
                  {{ scope.row.account }} ({{ scope.row.platform.toUpperCase() }})
                </span>
                  </template>
                </el-table-column>

                <el-table-column
                  :label="`${this.$t('userid')}`">
                  <template slot-scope="scope">
                    <span :class="handleLineClass(scope.row.counter)">{{ scope.row.userid }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  :label="`${this.$t('is_master')}`">
                  <template slot-scope="scope">
                    <span :class="handleLineClass(scope.row.counter)">{{ scope.row.type }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  :label="`${this.$t('servertxt')}`">
                  <template slot-scope="scope">
                <span v-on:click="copyit(scope.row.server)" :class="`clickable ${handleLineClass(scope.row.counter)}`">
                  {{ scope.row.server }}
                </span>
                  </template>
                </el-table-column>

                <el-table-column
                  :label="`${this.$t('failedsince')}`">
                  <template slot-scope="scope">
                    <span :class="handleLineClass(scope.row.counter)">{{ scope.row.failed_since }}</span>
                  </template>
                </el-table-column>

                <el-table-column width="120" align="center"
                  :label="`${this.$t('revalidations_counter')}`">
                  <template slot-scope="scope">
                    <span :class="handleLineClass(scope.row.counter)">{{ scope.row.counter }}</span>
                  </template>
                </el-table-column>

              </el-table>

              <input id="clipit" type="text" v-model="clipboard" style="display: none">
            </div>
            <div class="col-sm-6 pagination-info">
              <p class="category">Showing {{ from + 1 }} to {{ to }} of {{ total }} entries</p>
            </div>
            <div class="col-sm-6">
              <p-pagination class="pull-right"
                            v-model="pagination.currentPage"
                            :per-page="pagination.perPage"
                            :total="pagination.total">
              </p-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </module>
</template>
<script>
  import Vue from 'vue'
  import {Tooltip, Table, TableColumn, Select, Option} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import {Button, Modal} from 'src/components/UIComponents'
  import ManualRevalidation from "@/components/Dashboard/Views/Dashboard/ChangeLogs/manual-revalidation.vue";

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  Vue.use(require('vue-moment'))

  const user_data = JSON.parse(localStorage.getItem('user-info'));

  let bills = [];
  let timeoutcount = 1500;

  export default {
    components: {
      ManualRevalidation,
      PPagination, [Button.name]: Button, Modal,
      [Tooltip.name]: Tooltip
    },
    computed: {
      pagedData () {
        return this.tableData.slice(this.from, this.to)
      },
      /***
       * Searches through table data and returns a paginated array.
       * Note that this should not be used for table with a lot of data as it might be slow!
       * Do the search and the pagination on the server and display the data retrieved from server instead.
       * @returns {computed.pagedData}
       */
      queriedData () {
        if (!this.searchQuery) {
          this.pagination.total = this.tableData.length
          return this.pagedData
        }
        let result = this.tableData
          .filter((row) => {
            let isIncluded = false
            for (let key of this.propsToSearch) {
              //let rowValue = row[key].toString()
              let searchRegex = new RegExp(this.searchQuery, 'gi');
              //if (rowValue.includes && rowValue.includes(this.searchQuery.toLowerCase())) {
              if (row[key].toString().match(searchRegex) !== null) {
                isIncluded = true
              }
            }
            return isIncluded
          })
        this.pagination.total = result.length
        return result.slice(this.from, this.to)
      },
      to () {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from () {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total () {
        this.pagination.total = this.tableData.length
        return this.tableData.length
      }
    },
    data () {
      return {
        perpageTxt: this.$t('perpage'),
        sortbyTxt: this.$t('sortby'),
        sortbyOrder: this.$t('sortbyorder'),
        searchTxt: this.$t('searchtxt'),
        colSort: 'account',
        inprogress: true,
        actionlbl: this.$t('action'),
        alert_me: false,
        alert_msg: null,
        alert_class: 'text-danger',
        modalup: false,
        acc: {},
        clipboard: '',
        helper_accentry: this.$t('helper_accentry'),
        helper_accserver: this.$t('helper_accserver'),
        helper_accbroker: this.$t('helper_accbroker'),
        helper_accpwd: this.$t('helper_accpwd'),
        autoResultsBroker: [],
        autoResults: [],
        showAutoResultsBroker: false,
        alert_me_modal: false,
        brokers: [],
        brokerlist: [],
        showAutoResults: false,
        inprogresstxt: this.$t('loadingtxt') +" "+ this.$t('revalidations') +". "+ this.$t('pleasewait'),
        sortby: {
          column: 'account',
          order: 'asc',
          orders: [
            {
              prop: 'asc',
              label: this.$t('ascending')
            },
            {
              prop: 'desc',
              label: this.$t('descending')
            }
          ],
          columns: [
            {
              prop: 'account',
              label: this.$t('account')
            },
            {
              prop: 'userid',
              label: this.$t('userid')
            },
            {
              prop: 'type',
              label: this.$t('is_master')
            },
            {
              prop: 'server',
              label: this.$t('servertxt')
            },
            {
              prop: 'failed_since',
              label: this.$t('failedsince')
            },
            {
              prop: 'counter',
              label: this.$t('revalidations_counter')
            }
          ]
        },
        pagination: {
          perPage: 50,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0
        },
        searchQuery: '',
        propsToSearch: ['userid', 'server', 'account', 'platform', 'type', 'counter'],
        tableColumns: [
          {
            prop: 'userid',
            label: this.$t('userid')
          },
          {
            prop: 'is_master_txt',
            label: this.$t('is_master')
          },
          {
            prop: 'server',
            label: this.$t('servertxt')
          },
          {
            prop: 'failed_since',
            label: this.$t('failedsince')
          },
          {
            prop: 'counter',
            label: this.$t('revalidations_counter')
          }
        ],
        tableData: [],
        modalconfirm: false,
        passid: null,
        did: null,
        loadstep: -1,
        modalrevalidation: false,
        account: {},
      }
    },
    methods: {
      handleLineClass(counter) {
        if (counter > 3 && counter < 10)
          return 'custom-line-warning';
        if (counter > 10)
          return 'custom-line-danger'
        return '';
      },
      copyit(value) {
        let cb = document.getElementById("clipit");
        cb.style.display = 'block';
        cb.value = value;
        cb.select();
        document.execCommand('copy');
        cb.style.display = 'none';
        this.$toast.success(`${value} Copied!`)
      },
      closeModalAlert() {
        this.modalconfirm = false;
        this.did = null;
        this.acc = {};
      },
      completedTask() {
        this.$notifyAccountRevalidationFailed({
          'broker_name': this.acc.broker,
          'account_number': this.acc.account,
          'userid': this.acc.userid,
        })
        this.closeModalAlert();
        setTimeout(this.getValids, timeoutcount);
      },
      revalidateAll() {
        const faildRevalidation = error => {
          this.$toast.error(this.$t('ERROR_UNEXPECTED'));
          this.getValids()
        };
        const sucessRevalidation = resp => {
          this.$toast.info(this.$t('all_entries_were_sent_to_revalidation'));
          this.inprogress = true;
          this.getValids()
        }

        this.$revalidateManualAll().then(sucessRevalidation, faildRevalidation)
      },
      confirmedDel() {
        console.log(this.acc)
        if (this.acc.is_master) {
          const removedStratId = n => {
            this.acc.is_master = false;
            this.confirmedDel();
          };
          this.$delStrategy(this.acc.userid, this.acc.strat_id).then(removedStratId, this.failop);
        } else {
          this.$delAccount({
            userid: this.acc.userid,
            account: this.acc.account,
            platform: this.acc.platform,
            server: this.acc.server,
          }).then(this.completedTask, this.failop);
        }
      },
      updatedAssets() {
        this.modalassets = false;
        this.alert_me_modal = false;
        this.alert_msg = null;
        this.alert_class = 'text-danger';

        this.assets = null;
        this.msid = null;
        this.aid = null;
        this.uid = null;
        this.completed = false;
      },
      updateStrategy() {
        this.$upsertStrategy(this.uid, this.master.settings, this.msid, this.aid).then(this.updatedAssets, this.failop);
      },
      done(id) {
        this.did = id;
        this.modalconfirm = true;
      },
      resetAlert() {
        this.alert_me = false;
        this.alert_msg = null;
        this.alert_class = 'text-danger';
      },
      delacc(id) {
        this.did = id;
        const midx = this.tableData.findIndex(t => parseInt(t.id) === parseInt(this.did));
        console.log(midx);
        if (midx >= 0) {
          this.acc = this.tableData[midx];
          console.log(this.acc);
          this.modalconfirm = true;
        }
      },
      modify(id) {
        //console.log(id);
        this.did = id;
        const midx = this.tableData.findIndex(t => parseInt(t.id) === parseInt(this.did));
        //console.log(midx);
        if (midx >= 0) {
          this.acc = this.tableData[midx];
          console.log(this.acc);
          this.modalup = true;
        }
      },
      pickedServer(server) {
        this.acc.server = server;
        this.showAutoResults = false;
        this.autoResults = [];
      },
      pickedBroker(broker) {
        this.acc.broker = broker;
        this.showAutoResultsBroker = false;
        this.autoResultsBroker = [];
      },
      loadBrokers(resp) {
        this.brokers = [];
        this.brokerlist = [];

        for(var j=0;j < resp.data.length;j++) {
          if (this.brokerlist.findIndex(bl => bl.prop == resp.data[j].broker) < 0) {
            this.brokerlist.push({ 'prop': resp.data[j].broker, 'label': resp.data[j].broker});
          }
          this.brokers.push({ 'id': resp.data[j].server, 'name': resp.data[j].server, 'broker': resp.data[j].broker });
          //this.brokers.push(resp.servers[j].server);
        }
        if (this.brokerlist.length > 0) {
          this.brokerlist.push({ 'prop': "Other", 'label': "Other" });
        }
        // Done
        this.inprogress = false;
      },
      loadReValidations(resp) {
        for(var i=0;i < resp.data.length;i++) {
          let s = resp.data[i];
          s.counter = s.counter.toString()
          s.failed_since = this.$moment(s.failed_since * 1000).format("YYYY-MM-DD HH:mm:SS");
          this.tableData.push(s);
        }
        this.sortByCol();
        this.$getServers().then(this.loadBrokers, this.failop);
      },
      showpass(id, pwd) {
        const el = document.createElement('textarea');
        el.value = pwd;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        if (this.passid !== null && this.passid !== id) {
          const prid = this.tableData.findIndex(t => t.id === this.passid);
          this.tableData[prid].showingpass = false;
        }

        const rid = this.tableData.findIndex(t => t['id'] == id);
        console.log(rid);
        if (rid >= 0) {
          this.passid = rid;
          this.tableData[rid].showingpass = true;
          const removeShowingPass = id => {
            this.tableData[rid].showingpass = false;
            this.passid = null;
          }
          setTimeout(removeShowingPass, timeoutcount);
        }
        this.$toast.success(this.$t('password') +' '+ this.$t('copied'))
      },
      sortItNum (a, b) {
        if (this.sortby.order == 'asc') {
          return a[this.colSort] - b[this.colSort];
        }
        return b[this.colSort] - a[this.colSort];
      },
      sortIt (a, b) {
        if (this.sortby.order == 'asc') {
          if (a[this.colSort] < b[this.colSort])
            return -1;
          if (a[this.colSort] > b[this.colSort])
            return 1;
        }
        if (b[this.colSort] < a[this.colSort])
          return -1;
        if (b[this.colSort] > a[this.colSort])
          return 1;
        return 0;
      },
      sortByCol() {
        this.colSort = this.sortby.column;
        // is_master and revalidations_counter sort not working
        if (this.colSort === 'userid' || this.colSort === 'type' || this.colSort === 'server' || this.colSort ==='failed_since') {
          this.tableData.sort(this.sortIt);
        }
        else {
          this.tableData.sort(this.sortItNum);
        }
      },
      failop (error) {
        if (error.data !== undefined && error.data.msg !== undefined) {
          error = error.data.msg;
        }
        this.inprogress = false
        this.$toast.error(this.$t(error))
      },
      searchBrokers(k) {
        if (this.acc.broker !== undefined && this.acc.broker !== null && this.acc.broker.length > 0) {
          this.autoResultsBroker = [];

          for(var i=0;i < this.brokerlist.length;i++) {
            let r = new RegExp(this.acc.broker, "gi");
            if (this.brokerlist[i].label.match(r) !== null) {
              this.autoResultsBroker.push(this.brokerlist[i].label);
            }
          }
          this.showAutoResultsBroker = this.autoResultsBroker.length > 0;
        }
        else {
          this.showAutoResultsBroker = false;
        }
      },
      searchServers(k) {
        if (this.acc.server !== undefined && this.acc.server !== null && this.acc.server.length > 0) {
          this.autoResults = [];

          for(var i=0;i < this.brokers.length;i++) {
            let r = new RegExp(this.acc.server, "gi");
            if (this.brokers[i].name.match(r) !== null) {
              if (this.brokers[i].broker == this.acc.broker) {
                this.autoResults.push(this.brokers[i].name);
              }
            }
          }
          this.showAutoResults = this.autoResults.length > 0;
        }
        else {
          this.showAutoResults = false;
        }
      },
      getValids() {
        this.tableData = [];
        this.loadstep = -1;
        this.$getReValidations_v2().then(this.loadReValidations, this.failop);
      },
      accountUpdated(resp) {
        this.modalup = false;
        setTimeout(this.getValids, timeoutcount);
      },
      validate() {
        if (this.acc.account && this.acc.server && this.acc.password) {
          const json_data = {
            "password": this.acc.password,
            "platform": "mt4",
            "broker": this.acc.broker,
            "server": this.acc.server,
            "centaccount": this.acc.centaccount
          };
          //console.log(json_data);
          this.$addAccount_v2({
            eml: this.acc.userid,
            account: this.acc.account,
            newacc: this.newAccount,
            json_data: json_data
          }).then(this.accountUpdated, this.failop);
        }
        else {
          this.modalup = false;
          this.failop(this.$t('populatefieldbelow'));
        }
      },
      forceRevalidation(row) {
        this.account = row;
        this.account.present = `${row.account} (${row.type}) (${row.platform})`;
        if (row.platform == 'mt4') {
          this.account.symbol = 'EURUSD'
        }
        this.modalrevalidation = true;
      },
      sendToRevalidate() {
        if (!this.account.symbol) {
          this.$toast.warning(this.$t('warninginformaccountsymbol'))
          return;
        }
        this.$addAccountToPriorityRev({
          account: this.account.account,
          password: this.account.password,
          server: this.account.server,
          type: this.account.type,
          symbol: this.account.symbol,
          platform: this.account.platform,
        }).then(this.addSuccessHandler, this.addErrorHandler);
      },
      addSuccessHandler(resp) {
        if (!resp.success) {
          this.addErrorHandler(resp)
          return;
        }
        this.modalrevalidation = false;
        this.account = {};
        this.$toast.success(this.$t('accountrevalidationpipelined'))
        this.$refs.module.refreshList();
        setTimeout(this.getValids, timeoutcount)
      },
      addErrorHandler(resp) {
        console.log(resp)
        this.$toast.warning(this.$t('failtopipelineaccountrevalidation'));
      }
    },
    mounted () {
      // Admin User or NOT
      if (user_data['roles'].indexOf('admin') >= 0) {
        this.adminusr = true;
      }
      this.ueml = user_data.email;

      this.getValids();
    },
    created(){
      this.$setTranslatingInProgress()
    },
    beforeDestroy(){
      this.$root.$off("showTranslatingProgress")
    },
  }
</script>
<style>
  .auto-ul {
    list-style: none;
    padding: 5px;
    overflow: scroll;
    height: 100px;
    position: absolute;
    background-color: white;
    z-index: 9999;
    border: 1px solid silver;
    width: 93%;
  }
  .auto-ul li:hover {
    background: silver;
    cursor: pointer;
  }

  .custom-line-warning {
    font-weight: bold;
    color: #ffd507;
  }
  .custom-line-danger {
    font-weight: bold;
    color: #ff4646;
  }
</style>
