<template>
  <div>
    <el-tooltip  content="EA manual revalidation">
      <p-button link v-on:click="callManualRevalidation()">
      <i class="fa fa-wrench" style="font-size: x-large;"></i>
    </p-button>
    </el-tooltip>
  </div>
</template>
<script>
import {Tooltip} from 'element-ui';

export default {
  name: "manual-revalidation",
  components: {
    [Tooltip.name]: Tooltip
  },
  props: {
    account: {
      type: String,
      description: 'Account number for ea manual revalidation'
    }
  },
  methods: {
    callManualRevalidation() {
      let success = resp => {
        if(resp.success) {
          this.$toast.success(this.$t('account_sent_to_manual_validation'))
        }
      }
      let fail = resp => {
        this.$toast.error(this.$t('somethingwentwrong'))
      }
      this.$manualRevalidation(this.account).then(success, fail)
    },
  }
}
</script>


<style scoped>

</style>
